<template>
	<div class="app-main__inner">
		<div class="main-card mb-3">
			<!--			<div class="card-body">-->
			<div class="contaner mb-3 mt-3">
				<div class="flex justify-between">
					<h2 class="text-purple-600">{{ $t('discrepancyReport') }}</h2>
					<div>
<!--						<button v-on:click="downloadExcelReport" :disabled="generating"-->
<!--								class="border-2 border-purple-600 text-purple-600 hover:text-purple-600 p-2 rounded-md">-->
<!--							<span class="m-auto">{{ $t('exportExcel') }}</span>-->
<!--						</button>-->
					</div>
				</div>
			</div>
			<div class="continer">
				<div class="main-card mb-3 card">
					<div class="card-body">
						<div class="row sticky-table mb-3 mt-3">
							<div class="col-lg-4 col-md-4">
                                    <span>
                                        <el-date-picker
											class="w-full text-xs font-light app-input"
											v-model="dateRange"
											type="daterange"
											value-format="yyyy-MM-dd"
											format="yyyy/MM/dd"
											start-placeholder="Startdatum"
											end-placeholder="Slutdatum">
                                        </el-date-picker>
                                    </span>
							</div>
							<div class="col-lg-2 col-md-2">
								<div class="flex md:justify-center">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4">
										{{ $t('apps') }}:
									</div>
									<div class="form-group">
										<select id="app-name-id" v-model="app_type"
												class="text-xs form-select w-full">
											<option value="all">{{ $t('all') }}</option>
											<option :value="app.key" v-for="(app, i) in app_types"
													:key="i">{{
													app.name
												}}
											</option>
										</select>
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-3">
								<div class="flex">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4">
										<!-- <i class="iconly-brokenFilter mr-1"></i> -->
										{{ $t('elementType') }}:
									</div>
									<div class="form-group">
										<select class="text-xs form-select w-full removs-bg" v-model="elementType">
											<option value="all">{{ $t('all') }}</option>
											<option value="Invoice">{{ $t('invoice') }}</option>
											<option value="Order">{{ $t('order') }}</option>
											<option value="Voucher">{{ $t('voucher') }}</option>
											<option value="Customer">{{ $t('customer') }}</option>
											<option value="Article">{{ $t('article') }}</option>
											<option value="Invoice Payment">{{ $t('invoice_payment') }}</option>
											<option value="Invoice Accrual">{{ $t('invoice_accrual') }}</option>
											<!--												<option v-for="item in elementTypes" :key="item">{{-->
											<!--														item-->
											<!--													}}-->
											<!--												</option>-->
										</select>
									</div>
								</div>
							</div>
							<div class="col-lg-3 col-md-3">
								<div class="flex">
									<div class="form-group">
										<button v-on:click="downloadExcelReport" :disabled="loading"
												class="flex justify-content-center bg-purple-600 px-4 py-2 rounded-md text-white">
											<span>{{ $t('generateReport') }}</span><span v-if="loading === true"
																						 class="ml-3"><i
											class="fa fa-spinner fa-spin"></i></span>
										</button>
									</div>
								</div>
							</div>
						</div>
						<table style="width: 100%;" id="examplemmm"
							   class="table table-hover table-striped table-bordered">
							<thead>
							<tr>
								<th class="text-xs">{{ $t('apps') }}</th>
								<th class="text-xs">{{ $t('elementType') }}</th>
								<th class="text-xs">{{ $t('order_reference') }}</th>
								<th class="text-xs">{{ $t('detail') }}</th>
								<th class="text-xs">{{ $t('date') }}</th>
							</tr>
							</thead>
							<tbody v-loading='vloading'>
							<tr v-for="(log, i) in GET_SYNC_REPORT.results" :key="i">
								<td class="text-xs font-light">{{ log.app_name }}</td>
								<td class="text-xs font-light">{{ log.element_type }}</td>
								<td class="text-xs font-light">{{ log.order_reference }}</td>
								<td class="text-xs font-light"><p class="max-w-2xl">
									{{ truncateString(log.reason, 200) }}</p></td>
								<td class="text-xs font-light">{{ formatDateMoment(log.at) }}</td>
							</tr>
							</tbody>
						</table>

						<div class="float-right mb-5">
							<paginate
								:page-count="getPageCount"
								:click-handler="changePageNumber"
								:prev-text="$t('prev')"
								:next-text="$t('next')"
								:container-class="'pagination'">
							</paginate>
						</div>
					</div>
				</div>
			</div>
			<!--			</div>-->
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Paginate from 'vuejs-paginate'
import debounce from 'lodash/debounce'
import {DatePicker} from 'element-ui'
import download from 'downloadjs'
import moment from "moment";
import {ELEMENT_TYPES, ALLOWED_V3_SYNC_REPORT_APPS} from "../../app/index"

Vue.use(DatePicker)

export default {
	name: 'app-logs',
	components: {
		paginate: Paginate,
		// DatePicker
	},
	data() {
		return {
			AppLogs: [],
			copyAppLog: [],
			sytemAppLogs: [],
			copySystemLogs: [],
			dateRange: '',
			value2: '',
			app_types: [],
			elementTypes: ELEMENT_TYPES,
			perPage: 50,
			currentPage: 1,
			loading: false,
			currentSystemPage: 1,
			elementType: 'all',
			app_type: 'all',
			generating: false,
			vloading: false,
		}
	},
	computed: {
		getItems: function () {
			const current = this.currentPage * this.parPage
			const start = current - this.parPage
			return this.GET_APP_LOG.slice(start, current)
		},
		getPageCount: function () {
			return Math.ceil(this.GET_SYNC_REPORT.count / this.perPage)
		},
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_USER_COMPANY_ID: 'customer/GET_USER_COMPANY_ID',
			GET_SYSTEM_LOGS: 'customer/GET_SYSTEM_LOGS',
			GET_APP_TYPE: 'customer/GET_APP_TYPE',
			GET_APP_LOG: 'customer/GET_APP_LOG',
			GET_SYNC_REPORT: 'customer/GET_SYNC_REPORT',
			GET_USER: 'customer/GET_USER',
		})
	},
	mounted() {
		this.getSyncLogs()
		this.getSubscriptionList()
	},
	methods: {
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {
				this.filterReports(event)
			} else {
				this.$store.commit('customer/DEFAULT_APP_LOGS')
			}
		}, 500),
		filterReports(event) {
			this.$store.commit('customer/FILTER_USER_LOGS', event)
		},
		changePageNumber(pageNum) {
			this.currentPage = Number(pageNum)
			let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SYNC_REPORTS + `?user_id=${this.GET_USER_COMPANY_ID}` : this.$services.endpoints.SYNC_REPORTS
			URL += `?limit=${this.perPage}&page=${this.currentPage}`
			if (this.computeFilter()) {
				URL += this.computeFilter()
			}
			// if (this.dateRange) {
			// 	URL += `&date_start=${this.dateRange[0]}&date_end=${this.dateRange[1]}`
			// }
			// if (this.app_type !== 'all') {
			// 	URL += `&app_type=${this.app_type}`
			// }
			// if (this.elementType !== 'all') {
			// 	URL += `&element_type=${this.elementType}`
			// }
			this.$store.dispatch('customer/getSyncReport', {URL})
				.then(_ => {
					this.loading = false
				}).catch(err => {
				this.loading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSyncLogs()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		formatDateMoment(dateAt) {
			if (dateAt) {
				return moment(dateAt).format('YYYY-MM-DD HH:MM a')
			} else {
				return ''
			}
		},
		truncateString(str, num) {
			if (str) {
				if (str.length > num) {
					return str.slice(0, num) + "...";
				} else {
					return str;
				}
			} else {
				return ''
			}
		},
		generateReport: function () {
			let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SYNC_REPORTS + `?limit=50&user_id=${this.GET_USER_COMPANY_ID}` : this.$services.endpoints.SYNC_REPORTS + '?limit=50'

			// if (this.dateRange) {
			// 	URL += `&date_start=${this.dateRange[0]}&date_end=${this.dateRange[1]}`
			// }
			// if (this.app_type !== 'all') {
			// 	URL += `&subscription__app_type=${this.app_type}`
			// }
			// if (this.elementType !== 'all') {
			// 	URL += `&element_type=${this.elementType}`
			// }
			if (this.computeFilter()) {
				URL += this.computeFilter()
			}
			this.loading = true
			this.$store.dispatch('customer/getSyncReport', {URL})
				.then(_ => {
					this.loading = false
				}).catch(err => {
				this.loading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.generateReport()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		computeFilter() {
			let URL = ''
			if (this.dateRange) {
				URL += `&date_start=${this.dateRange[0]}&date_end=${this.dateRange[1]}`
			}
			if (this.app_type !== 'all') {
				URL += `&subscription__app_type=${this.app_type}`
			}
			if (this.elementType !== 'all') {
				URL += `&element_type=${this.elementType}`
			}
			return URL
		},
		dateFilterAudit(e) {
			this.$store.commit('customer/FILTER_AUDIT_DATE', e)
		},
		getSyncLogs() {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SYNC_REPORTS + `?user_id=${this.GET_USER_COMPANY_ID}` : this.$services.endpoints.SYNC_REPORTS
			this.$store.dispatch('customer/getSyncReport', {URL})
				.then(_ => {
					this.loading = false
				}).catch(err => {
				this.loading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSyncLogs()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		downloadExcelReport() {
			// const loading = this.$loading({
			// 	lock: true,
			// 	text: this.$t('pleaseWait'),
			// 	spinner: 'el-icon-loading',
			// 	background: 'rgba(0, 0, 0, 0.7)'
			// })
			this.generating = true
			this.loading = true
			let URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.SYNC_REPORTS + `excel/?user_id=${this.GET_USER_COMPANY_ID}` : this.$services.endpoints.SYNC_REPORTS + 'excel?a=1'
			if (this.dateRange) {
				URL += `&date_start=${this.dateRange[0]}&date_end=${this.dateRange[1]}`
			}
			if (this.app_type !== 'all') {
				URL += `&subscription__app_type=${this.app_type}`
			}
			if (this.elementType !== 'all') {
				URL += `&element_type=${this.elementType}`
			}
			this.$store.dispatch('customer/exportSyncReport', {URL}).then(res => {
				const content = res.headers['content-type']
				download(res.data, 'sync_report.xlsx', content)
				this.generating = false
				this.loading = false
			}).catch(err => {

				this.generating = false
				this.loading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.downloadExcelReport()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
				this.$services.helpers.notification('Fel vid export av synkroniseringsrapportdata', 'error', this)
			})
		},
		getSubscriptionList(key = null) {
			const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_SUBSCRIPTION + `${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_SUBSCRIPTION
			this.$store.dispatch('customer/getSubscriptionList', {URL})
				.then(res => {
					let all_subscriptions = res.data.data.all_subscriptions
					try {
						ALLOWED_V3_SYNC_REPORT_APPS.map(item => {
							let exist = all_subscriptions.filter(sub => parseInt(sub.app_type) === parseInt(item.key))
							if (exist.length > 0) {
								this.app_types.push(item)
							}
						})
					} catch (e) {
					}

				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSubscriptionList(key)
							})
							.catch(_ => {
								// console.log('error response')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	background-repeat: no-repeat;
	background-color: #fff;
	border-color: #e2e8f0;
	border-width: 1px;
	border-radius: 0.25rem;
	padding-top: 0.3rem !important;
	padding-right: 2.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	font-size: 0.9rem !important;
	line-height: 1.5;
	background-position: right 0.5rem center;
	background-size: 1.5em 1.5em;
}

.sticky-table {
	position: -webkit-sticky;
	position: sticky;
	top: 61px;
	background-color: #fff;
	// background-color: #e8eaec;
	height: 50px;
	padding: 7px;
}

.card-body {
	padding: 0 0.9rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.body-tabs-shadow .body-tabs-animated {
	padding: unset !important;
}

.tab-btn.active {
	box-shadow: unset !important;
	border: 1px solid $theme-secondary-color !important;
	font-weight: 800;
	color: $theme-secondary-color !important;
	border-radius: 5px;
}

.tab-btn {
	color: $theme-secondary-light;
}

.remove-bg {
	background: unset !important;
	background-color: unset !important;
}
</style>
