<template>
	<div class="app-main__inner">
		<div class="main-card mb-3 card">
			<div class="card-body">
				<div class="row sticky-table mb-3 mt-3">
					<div class="col-lg-12 col-md-12">
						<div class="w-full">
							<div
								class="flex text-xs font-light justify-between align-items-center mr-1 -mt-4">
								{{ $t('apps') }}:
							</div>
							<div class="form-group mt-2">
								<el-select v-model="sub" remote
										   reserve-keyword
										   :remote-method="remoteMethod" :placeholder="$t('search')"
										   filterable
										   v-on:change="onChangeSubscription"
										   class="w-1/4"
										   size="mini">
									<el-option
										:key="`all`"
										:label="`${$t('all')}` "
										:value="`all`">
									</el-option>
									<el-option
										v-for="(item,key) in subscriptionList"
										:key="key"
										:label="`(${item.id})  ${item.app_type_name} - ${item.company_name}` "
										:value="item.id">
									</el-option>
								</el-select>
							</div>
						</div>
					</div>

				</div>

			</div>
		</div>
		<div class="">
			<div class="w-full table-responsive bg-white table-container">
				<table id="examplemmm" v-loading="loading"
					   class="table table-hover table-striped table-bordered bg-white table-auto overflow-x-scroll w-full block">
					<thead>
					<tr>
						<th class="text-xs">
							<span class="flex justify-between px-2">
														<span>
														{{ $t('subscription') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('subscription_id')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('-subscription_id')"></i>
												</span>
													</span>
						</th>
						<th class="text-xs">
						<span class="flex justify-between px-2">
														<span>
														{{ $t('username') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('username')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('-username')"></i>
												</span>
													</span>
						</th>
						<th class="text-xs">{{ $t('app_settings_link') }}</th>
						<th class="text-xs">
						<span class="flex justify-between px-2">
														<span>
														{{ $t('last_processed_date') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('last_processed_date')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('-last_processed_date')"></i>
												</span>
													</span>
						</th>
						<!--							<th class="text-xs"></th>-->
					</tr>
					</thead>
					<tbody>
					<generic-row @removeObj="removeObj" :show-delete="true" :show-view="true"
								 :data-obj="invoice" :mode="`admin`" :display-field="displayField"
								 :date-time-field="dateTimeField" :edit-option="editOptions"
								 :endpoint="`${$services.endpoints.GET_UNPROCESSED_SUBSCRIPTION}/`"
								 :subscription-mode="`full`"
								 v-for="(invoice, i) in unprocessedSubscription.results" :key="i"/>
					</tbody>
				</table>
			</div>
			<div class="mb-5 mt-3" v-if="unprocessedSubscription.results.length > 0">
				<div class="flex justify-between">
					<div>
						<select class="text-xs form-select w-full removs-bg" v-on:change="onChangeLimit"
								v-model="limit"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="150">150</option>
							<option value="200">200</option>

						</select>
					</div>
					<div>
						<paginate
							:page-count="unprocessedSubscription.total_page"
							:click-handler="changePageNumber"
							:prev-text="$t('prev')"
							:next-text="$t('next')"
							:container-class="'pagination'">
						</paginate>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import genericRow from "./genericResources/genericRow";
import 'vue-json-pretty/lib/styles.css';
import Paginate from "vuejs-paginate";

export default {
	name: "unprocessedSubscription",
	components: {
		genericRow, paginate: Paginate,
	},
	data: function () {
		return {
			vloading: false,
			dateTimeField: ['last_processed_date'],
			unprocessedSubscription: {
				results: [],

			},
			editOptions: {mode: 'default'},
			displayField: ["subscription",
				"username",
				"app_settings_link",
				"last_processed_date"],
			limit: 50,
			sub: '',
			sortValue: '',
			subscriptionList: [],
			loading: false
		}
	},
	methods: {
		removeObj: function (sub_id) {
			let customerIndex = this.unprocessedSubscription.results.findIndex(item => item.id === sub_id)
			if (customerIndex > -1) {
				this.unprocessedSubscription.results.splice(customerIndex, 1)
			}
		},
		getSubscriptionUnprocessed: function () {
			let URL = this.$services.endpoints.GET_UNPROCESSED_SUBSCRIPTION + `?page_size=${this.limit}`
			this.filterSubscription(URL)
		},
		sortTable: function (value) {
			this.sortValue = value
			let URL = this.$services.endpoints.GET_UNPROCESSED_SUBSCRIPTION + `?page_size=${this.limit}&ordering=${this.sortValue}`
			this.filterSubscription(URL)
		},
		filterSubscription: function (URL) {
			this.loading = true
			this.$store.dispatch('house/customGetRequest', {URL: URL})
				.then(res => {
					this.unprocessedSubscription = res.data.data
					this.loading = false
				}).catch(err => {
				if (err.response.status === 400) {
					this.loading = false
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		onChangeLimit: function () {
			window.localStorage.setItem('unprocessed_sub_limit', this.limit)
			let URL = this.$services.endpoints.GET_UNPROCESSED_SUBSCRIPTION + `?page_size=${this.limit}`
			if (this.sub !== 'all' && this.sub !== '') {
				URL += `&subscription_id=${this.sub}`
			}
			if (this.sortValue) {
				URL += `&ordering=${this.sortValue}`
			}
			this.filterSubscription(URL)
		},
		changePageNumber: function (number) {
			let URL = this.$services.endpoints.GET_UNPROCESSED_SUBSCRIPTION + `?page_size=${this.limit}&page=${number}`
			if (this.sub !== 'all' && this.sub !== '') {
				URL += `&subscription_id=${this.sub}`
			}
			if (this.sortValue) {
				URL += `&ordering=${this.sortValue}`
			}
			this.filterSubscription(URL)
		},
		onChangeSubscription: function () {
			let URL = this.$services.endpoints.GET_UNPROCESSED_SUBSCRIPTION + `?page_size=${this.limit}`
			if (this.sub !== 'all' && this.sub !== '') {
				URL += `&subscription_id=${this.sub}`
			}
			if (this.sortValue) {
				URL += `&ordering=${this.sortValue}`
			}
			this.filterSubscription(URL)
		},
		remoteMethod(query) {
			if (query !== '') {
				const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?search=${query}&page_size=50`
				this.$store.dispatch('house/getSubscriptionList', {URL: URL})
					.then(response => {
						this.subscriptionList = response.data.data.results

					}).catch((err) => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		getSubscriptionList() {
			const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION
			this.$store.dispatch('house/getSubscriptionList', {URL: URL})
				.then(response => {
					this.subscriptionList = response.data.data.results
				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		}
	},
	mounted() {
		this.limit = window.localStorage.getItem('unprocessed_sub_limit') !== null ? window.localStorage.getItem('unprocessed_sub_limit') : 200
		this.getSubscriptionUnprocessed()
		this.getSubscriptionList()
	}
}
</script>

<style scoped>

</style>
