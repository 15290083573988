<template>
	<div class="app-main__inner">
		<div class="main-card mb-3 mt-3">
			<div class="container mb-3 mt-2">
				<div class="flex justify-between">
					<h2 class="text-purple-600">{{ $t('discrepancyReport') }}</h2>
					<div>
						<button v-on:click="downloadExcelReport" :disabled="generating"
								class="border-2 border-purple-600 text-purple-600 hover:text-purple-600 p-2 rounded-md">
							<span class="m-auto">{{ $t('exportExcel') }}</span>
						</button>
					</div>
				</div>
			</div>
			<div class="contaier">
				<div class="main-card mb-3 card">
					<div class="card-body">
						<div class="row  mb-3 mt-3">
							<div class="col-lg-4 col-md-4">
								<div class="py-1">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 mt-1">
										{{ $t('date') }}:
									</div>
									<div>
										<el-date-picker
											class="w-full text-xs font-light app-input"
											v-model="dateRange"
											type="daterange"
											value-format="yyyy-MM-dd"
											format="yyyy/MM/dd"
											start-placeholder="Startdatum"
											end-placeholder="Slutdatum">
										</el-date-picker>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4">
								<div class="py-1">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 mt-1">
										{{ $t('apps') }}:
									</div>
									<div class="form-group w-full">
										<select id="app-name-id" v-model="app_type"
												class="text-xs form-select w-full">
											<option value="all">{{ $t('all') }}</option>
											<option :value="app.key" v-for="(app, i) in app_types"
													:key="i">{{
													app.name
												}}
											</option>
										</select>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4">
								<div class="py-1 ml-1">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 mt-1">
										<!-- <i class="iconly-brokenFilter mr-1"></i> -->
										{{ $t('elementType') }}:
									</div>
									<div class="form-group w-full">
										<select class="text-xs form-select w-full removs-bg" v-model="elementType">
											<option value="all">{{ $t('all') }}</option>
											<option value="Invoice">{{ $t('invoice') }}</option>
											<option value="Order">{{ $t('order') }}</option>
											<option value="Voucher">{{ $t('voucher') }}</option>
											<option value="Customer">{{ $t('customer') }}</option>
											<option value="Article">{{ $t('article') }}</option>
											<option value="Invoice Payment">{{ $t('invoice_payment') }}</option>
											<option value="Invoice Accrual">{{ $t('invoice_accrual') }}</option>
										</select>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4">
								<div class="py-1">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 mt-1">
										{{ $t('company') }}:
									</div>
									<div class="form-group w-full">
										<el-select v-model="company" :placeholder="$t('search')" filterable
												   class="w-full"
												   size="mini" clearable
												   remote
												   reserve-keyword
												   :remote-method="remoteMethod">
											<el-option
												v-for="(item,key) in companyList"
												:key="key"
												:label="`(${item.id})  ${item.company.name}` "
												:value="item.id">
											</el-option>
										</el-select>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4">
								<div class="py-1">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 mt-1">
										{{ $t('subscription') }}:
									</div>
									<div class="form-group w-full">
										<el-select v-model="sub" :placeholder="$t('search')" filterable clearable
												   class="w-full"
												   remote
												   reserve-keyword
												   :remote-method="remoteMethodSubscription"
												   size="mini">
											<el-option
												v-for="(item,key) in subscriptionList"
												:key="key"
												:label="`(${item.id})  ${item.app_type_name} - ${item.name}` "
												:value="item.id">
											</el-option>
										</el-select>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4">
								<div class="py-1">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 mt-1">
										{{ $t('code') }}:
									</div>
									<div class="form-group w-full">
										<el-select v-model="code" :placeholder="$t('search')" filterable
												   class="w-full" clearable
												   size="mini"
										>
											<el-option
												v-for="(item,key) in codeList"
												:key="key"
												:label="`${item}` "
												:value="item">
											</el-option>
										</el-select>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4">
								<div class="py-1">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 mt-1">
										{{ $t('status') }}:
									</div>
									<div class="form-group w-full">
										<el-select v-model="status" :placeholder="$t('search')" filterable
												   class="w-full" clearable
												   size="mini"
										>
											<el-option
												v-for="(item,key) in statusList"
												:key="key"
												:label="`${$t(`${item.name}`)}` "
												:value="item.key">
											</el-option>
										</el-select>
									</div>
								</div>
							</div>
							<div class="col-lg-4 col-md-4">
								<div class="py-1">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 mt-1">
										{{ $t('search') }}:
									</div>
									<div class="form-group w-full">
										<input type="text" class="form-input h-7 w-full text-xs"
											   :placeholder="$t('search')"
											   v-on:input="searchInput"
											   v-model="searchName"/>
									</div>
								</div>

							</div>
							<div class="col-lg-4 col-md-4">
								<div class="py-1">
									<div
										class="flex text-xs font-light justify-between align-items-center mr-1 mt-1">
										<!--										{{ $t('search') }}:-->
									</div>
									<div class="form-group w-full mt-3">
										<button v-on:click="generateReport" :disabled="loading"
												class="flex justify-content-center bg-purple-600 px-4 py-1 rounded-md text-white">
											<span>{{ $t('generate') }}</span><span v-if="loading === true"
																				   class="ml-3"><i
											class="fa fa-spinner fa-spin"></i></span>
										</button>
									</div>
								</div>

							</div>
						</div>


					</div>
				</div>
				<div>
					<div class="w-full table-responsive bg-white table-container" v-loading="vloading">
						<table style="width: 100%;" id="examplemmm"
							   class="table table-hover table-striped table-bordered bg-white table-auto overflow-x-scroll w-full block">
							<thead>
							<tr>
								<th class="text-xs">
									<span class="flex justify-between px-2">
														<span>
														{{ $t('apps') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('subscription_id')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('-subscription_id')"></i>
												</span>
													</span>
								</th>
								<th class="text-xs">
									<span class="flex justify-between px-2">
														<span>
														{{ $t('elementType') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('element_type')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('-element_type')"></i>
												</span>
													</span>
								</th>
								<th class="text-xs">{{ $t('order_reference') }}
								</th>
								<th class="text-xs">{{ $t('code') }}
								</th>
								<th class="text-xs">{{ $t('status') }}
								</th>
								<th class="text-xs">{{ $t('detail') }}
								</th>
								<th class="text-xs">
								<span class="flex justify-between px-2">
														<span>
														{{ $t('date') }}
													</span>
													<span class="relative">
													<i class="fa fa-chevron-up absolute bottom-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('at')"></i>
													<i class="fa fa-chevron-down absolute top-2 cursor-pointer icon-size"
													   v-on:click.prevent="sortTable('-at')"></i>
												</span>
													</span>
								</th>
							</tr>
							</thead>
							<tbody v-loading='vloading'>
							<tr v-for="(log, i) in reports.results" :key="i">
								<td class="text-xs font-light">{{ log.app_name }}</td>
								<td class="text-xs font-light">{{ log.element_type }}</td>
								<td class="text-xs font-light">{{ log.order_reference }}</td>
								<td class="text-xs font-light">{{ log.code }}</td>
								<td class="text-xs font-light">
									<span v-if="log.status === 'Success'"
										  class="badge bg-green-500 w-2/4 py-2 text-white">
										{{ $t(`success`) }}
									</span>
									<span v-else-if="log.status === 'Failed'"
										  class="badge bg-red-500 w-2/4 py-2 text-white">
										{{ $t(`failed`) }}
									</span>
									<span v-else class="badge bg-gray-500 w-2/4 py-2 text-white">
										{{ $t(`ambiguous`) }}
									</span>
								</td>
								<td class="text-xs font-light"><p class="max-w-2xl">
									{{ truncateString(log.reason, 200) }}</p></td>
								<td class="text-xs font-light">{{ formatDateMoment(log.at) }}</td>
							</tr>
							</tbody>
						</table>
					</div>

					<div class="float-right mt-3 mb-5">
						<paginate
							:page-count="getPageCount"
							:click-handler="changePageNumber"
							:prev-text="$t('prev')"
							:next-text="$t('next')"
							:container-class="'pagination'">
						</paginate>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import Paginate from 'vuejs-paginate'
import debounce from 'lodash/debounce'
import {DatePicker} from 'element-ui'
import download from 'downloadjs'
import moment from "moment";
import {ELEMENT_TYPES, ALLOWED_V3_SYNC_REPORT_APPS, ENABLED_V3_SYNC_APP} from "../../app/index"

Vue.use(DatePicker)

export default {
	name: 'syncEntryReport',
	components: {
		paginate: Paginate,
		// DatePicker
	},
	data() {
		return {
			AppLogs: [],
			addOn: true,
			copyAppLog: [],
			sytemAppLogs: [],
			reports: {results: []},
			dateRange: '',
			value2: '',
			app_types: ALLOWED_V3_SYNC_REPORT_APPS,
			companyList: [],
			subscriptionList: [],
			company: '',
			code: '',
			status: '',
			elementTypes: ELEMENT_TYPES,
			perPage: 50,
			currentPage: 1,
			loading: false,
			currentSystemPage: 1,
			elementType: 'all',
			app_type: 'all',
			searchName: '',
			sub: '',
			generating: false,
			vloading: false,
			sortValue: '',
			codeList: ['customer-not-posted', 'no_custom_account', 'order-not-posted', 'totals-mismatch', 'voucher-not-posted'],
			statusList: [
				{key: 'Failed', 'name': 'failed'},
				{key: 'Success', 'name': 'success'},
				{key: 'Ambiguous', 'name': 'ambiguous'},
			],
		}
	},
	computed: {
		getItems: function () {
			const current = this.currentPage * this.parPage
			const start = current - this.parPage
			return this.GET_APP_LOG.slice(start, current)
		},
		getPageCount: function () {
			return Math.ceil(this.reports.count / this.perPage)
		},
		...mapGetters({
			USER_TOKEN: 'auth/USER_TOKEN',
			USER_REFRESH: 'auth/USER_REFRESH',
			GET_SYSTEM_LOGS: 'customer/GET_SYSTEM_LOGS',
			GET_APP_TYPE: 'customer/GET_APP_TYPE',
			GET_APP_LOG: 'customer/GET_APP_LOG',
			GET_SYNC_REPORT: 'customer/GET_SYNC_REPORT',
		})
	},
	mounted() {
		this.getSyncLogs()
		this.getAllCompanies()
		this.getSubscriptionList()
	},
	methods: {
		sortTable: function (value) {
			this.sortValue = value
			let URL = this.$services.endpoints.SYNC_REPORTS + `?limit=${this.perPage}`
			URL += this.aggregateFilter()
			this.runFiltering(URL)

		},
		toggleaddOn: function () {
			this.addOn = !this.addOn
		},
		getAllCompanies() {
			this.$store.dispatch('house/getAllCompanies', {})
				.then(res => {
					this.companyList = res.data.results
				}).catch(err => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		searchInput: debounce(function () {
			let URL = this.$services.endpoints.SYNC_REPORTS
			if (this.searchName) {
				URL += `?limit=${this.perPage}&search=${this.searchName}`
				URL += this.aggregateFilter()
				this.runFiltering(URL)
			} else {
				this.getSyncLogs()
			}
		}, 500),

		remoteMethod: debounce(function (query) {
			if (query !== '') {
				this.$store.dispatch('house/searchCompany', {value: query, num: false})
					.then(res => {
						this.companyList = res.data.results
					}).catch(err => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {
								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		}, 500),
		searchInputTrigger: debounce(function (event) {
			if (event.target.value !== '') {
				this.filterReports(event)
			}
		}, 500),
		filterReports(event) {

		},
		changePageNumber(pageNum) {
			this.currentPage = Number(pageNum)
			let URL = this.$services.endpoints.SYNC_REPORTS
			URL += `?limit=${this.perPage}&page=${this.currentPage}`
			URL += this.aggregateFilter()
			this.runFiltering(URL)
		},
		remoteMethodSubscription(query) {
			if (query !== '') {
				const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?search=${query}&limit=500`
				this.$store.dispatch('house/getSubscriptionList', {URL: URL})
					.then(response => {
						this.subscriptionList = response.data.data.results

					}).catch((err) => {
					if (err.response.status === 400) {
						if (err.response.data.code === 401) {
							this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
								.then(_ => {

								})
								.catch(_ => {
									this.$store.commit('auth/CLEAR_AUTH_USER', null)
									window.Bus.$emit('sign-out')
								})
						}
					}
				})
			}
		},
		runFiltering: function (URL) {
			this.vloading = true
			this.$store.dispatch('house/customGet', {URL})
				.then(res => {
					this.vloading = false
					this.reports = res.data
				}).catch(err => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.getSyncLogs()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		formatDateMoment(dateAt) {
			if (dateAt) {
				return moment(dateAt).format('YYYY-MM-DD HH:MM a')
			} else {
				return ''
			}
		},
		truncateString(str, num) {
			if (str) {
				if (str.length > num) {
					return str.slice(0, num) + "...";
				} else {
					return str;
				}
			} else {
				return ''
			}
		},
		aggregateFilter() {
			let URL = ''
			if (this.dateRange) {
				URL += `&date_start=${this.dateRange[0]}&date_end=${this.dateRange[1]}`
			}
			if (this.app_type !== 'all') {
				URL += `&app_type=${this.app_type}`
			}
			if (this.code) {
				URL += `&code=${this.code}`
			}
			if (this.company) {
				URL += `&subscription__user__company__id=${this.company}`
			}
			if (this.status) {
				URL += `&status=${this.status}`
			}
			if (this.elementType !== 'all') {
				URL += `&element_type=${this.elementType}`
			}
			if (this.sub) {
				URL += `&subscription_id=${this.sub}`
			}
			if (this.sortValue) {
				URL += `&ordering=${this.sortValue}`
			}
			return URL
		},
		generateReport: function () {
			let URL = this.$services.endpoints.SYNC_REPORTS + '?limit=50'
			URL += this.aggregateFilter()
			this.loading = true
			this.vloading = true
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.loading = false
					this.vloading = false
					this.reports = res.data
				}).catch(err => {
				this.loading = false
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.generateReport()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		dateFilterAudit(e) {
			this.$store.commit('customer/FILTER_AUDIT_DATE', e)
		},
		getSyncLogs() {
			const URL = this.$services.endpoints.SYNC_REPORTS + `?limit=${this.perPage}`
			this.runFiltering(URL)
		},
		downloadExcelReport() {
			const loading = this.$loading({
				lock: true,
				text: this.$t('pleaseWait'),
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			})
			this.generating = true
			let URL = this.$services.endpoints.SYNC_REPORTS + 'excel?a=1'
			URL += this.aggregateFilter()
			this.$store.dispatch('house/exportSyncReport', {URL}).then(res => {
				const content = res.headers['content-type']
				download(res.data, 'sync_report.xlsx', content)
				loading.close()
				this.generating = false
			}).catch(err => {
				loading.close()
				this.generating = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {
								this.downloadExcelReport()
							})
							.catch(_ => {
								// console.log('here')
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
				this.$services.helpers.notification('Fel vid export av synkroniseringsrapportdata', 'error', this)
			})
		},

		getSubscriptionList() {
			const URL = this.$services.endpoints.GET_INHOUSE_SUBSCRIPTION + `?limit=500`
			this.$store.dispatch('house/getSubscriptionList', {URL: URL})
				.then(response => {
					this.subscriptionList = response.data.data.results

				}).catch((err) => {
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-select {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	-webkit-print-color-adjust: exact;
	color-adjust: exact;
	background-repeat: no-repeat;
	background-color: #fff;
	border-color: #e2e8f0;
	border-width: 1px;
	border-radius: 0.25rem;
	padding-top: 0.3rem !important;
	padding-right: 2.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0.75rem;
	font-size: 0.9rem !important;
	line-height: 1.5;
	background-position: right 0.5rem center;
	background-size: 1.5em 1.5em;
}

.sticky-table {
	position: -webkit-sticky;
	position: sticky;
	top: 61px;
	background-color: #fff;
	// background-color: #e8eaec;
	height: 50px;
	padding: 7px;
}

.card-body {
	padding: 0 0.9rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.body-tabs-shadow .body-tabs-animated {
	padding: unset !important;
}

.tab-btn.active {
	box-shadow: unset !important;
	border: 1px solid $theme-secondary-color !important;
	font-weight: 800;
	color: $theme-secondary-color !important;
	border-radius: 5px;
}

.tab-btn {
	color: $theme-secondary-light;
}

.remove-bg {
	background: unset !important;
	background-color: unset !important;
}
</style>
