<template>
	<div class="app-main__inner">
		<div class="row mb-2 mt-3">
			<div class="col-md-12">
				<div class="main-card mb-3 card">
					<div class="card-body">
						<div class="row sticky-table mb-3 mt-3">
							<div class="col-md-12">
								<div class="grid grid-cols-4 gap-3">
									<div class="form-group  w-full">
										<label>{{ $t('role') }}</label>
										<el-select v-model="filterList.role" clearable :placeholder="$t('role')"
												   filterable
												   v-on:change="onChange"
												   class="w-full h-10"
												   size="mini">
											<el-option
												v-for="(item,key) in userRole"
												:key="key"
												:label="$t(`${item.name}`)"
												:value="item.key">
											</el-option>
										</el-select>
									</div>
									<div class="form-group  w-full">
										<label>{{ $t('accounting') }}</label>
										<el-select v-model="filterList.accounting" :placeholder="$t('accounting')"
												   filterable clearable
												   v-on:change="onChange"
												   class="w-full h-10"
												   size="mini">
											<el-option
												v-for="(item,key) in accountingField"
												:key="key"
												:label="$t(`${item.name}`)"
												:value="item.key">
											</el-option>
										</el-select>
									</div>
									<div class="form-group  w-full">
										<label>{{ $t('active') }}</label>
										<el-select v-model="filterList.active" :placeholder="$t('active')"
												   filterable clearable
												   v-on:change="onChange"
												   class="w-full h-10"
												   size="mini">
											<el-option
												v-for="(item,key) in activeList"
												:key="key"
												:label="$t(`${item.name}`)"
												:value="item.key">
											</el-option>
										</el-select>
									</div>
									<div class="form-group  w-full">
										<label>{{ $t('status') }}</label>
										<el-select v-model="filterList.status" :placeholder="$t('status')"
												   filterable clearable
												   v-on:change="onChange"
												   class="w-full h-10"
												   size="mini">
											<el-option
												v-for="(item,key) in statusList"
												:key="key"
												:label="$t(`${item.name}`)"
												:value="item.key">
											</el-option>
										</el-select>
									</div>

								</div>
							</div>
							<div class="col-md-12 ">
								<div class="grid grid-cols-2 gap-2">
									<div>
										<el-date-picker
											class="w-full text-xs font-light"
											v-model="filterList.datetime"
											type="daterange"
											size="mini"
											clearable
											@change='onChange'
											value-format="yyyy-MM-dd"
											format="yyyy/MM/dd"
											start-placeholder="Start datum"
											end-placeholder="Slutet datum">
										</el-date-picker>
									</div>
									<div class="flex justify-start lg:justify-end">
										<div class="form-group w-full">
											<input @input="searchInputFilterOrder" type="text" v-model="searchName"
												   :placeholder="$t('search')"
												   class="form-input w-full text-xs">
										</div>
									</div>
								</div>

							</div>

						</div>

					</div>
				</div>
			</div>
		</div>
		<div>
			<div v-if="vloading" class="flex justify-center w-full">
				<svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-400"
					 xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
					<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
							stroke-width="4"></circle>
					<path class="opacity-75" fill="currentColor"
						  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
				</svg>
			</div>
			<div class="w-full table-responsive bg-white table-container">
				<table style="width: 100%;" id="examplemmm"
					   class="table table-hover table-striped table-bordered bg-white table-auto overflow-x-scroll w-full block">
					<thead>
					<tr>
						<th class="text-xs" v-for="(item,i) in displayField">
							<span class="flex justify-between">
								<span>{{ $t(`${item.name}`) }}</span>
								<span class="relative mr-1" v-if="item.has_sort === true">
													<i v-on:click.prevent="sortUser(`${item.sort_name}`)"
													   class="fa fa-chevron-up absolute bottom-2 cursor-pointer"></i>
													<i v-on:click.prevent="sortUser(`-${item.sort_name}`)"
													   class="fa fa-chevron-down absolute top-2 cursor-pointer"></i>
												</span>
							</span>
						</th>
						<th class="text-xs"></th>
					</tr>
					</thead>
					<tbody>
					<user-row :date-time-field="dateTimeField" :display-field="displayField"
							  v-for="(item,i) in users.results" :user="item" :key="i" @removeObj="removeUser"
							  :show-view="true" :show-delete="false" :show-edit="true"/>

					</tbody>
				</table>
			</div>
			<div class="mt-3 mb-5" v-if="users.results.length > 0">
				<div class="flex justify-between">
					<div>
						<select class="text-xs form-select w-full removs-bg" v-on:change="onChangeLimit"
								v-model="limit"
						>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="150">150</option>
							<option value="200">200</option>

						</select>
					</div>

					<paginate
						:page-count="users.total_page"
						:click-handler="changePageNumber"
						:prev-text="$t('prev')"
						:next-text="$t('next')"
						:container-class="'pagination'">
					</paginate>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import UserRow from "./userRow";
import 'vue-json-pretty/lib/styles.css';
import Paginate from "vuejs-paginate";

export default {
	name: "users",
	components: {UserRow, paginate: Paginate,},
	data: function () {
		return {
			role: '',
			searchName: '',
			limit: 50,
			users: {
				results: [],
				total_page: 1
			},
			displayField: [
				{name: 'id', has_sort: true, 'sort_name': 'id'},
				{name: 'username', has_sort: true, 'sort_name': 'username'},
				{name: 'email', has_sort: true, 'sort_name': 'email'},
				{name: 'admin_alert_email', has_sort: true, sort_name: 'admin_alert_email'},
				{name: 'status', has_sort: true, sort_name: 'status'},
				{name: 'first_name', has_sort: true, sort_name: 'first_name'},
				{name: 'last_name', has_sort: true, sort_name: 'last_name'},
				{name: 'company', has_sort: true, 'sort_name': 'company_id'},
				{name: 'is_accountant', has_sort: false, 'sort_name': 'is_accountant'},
				{name: 'role', has_sort: false, sort_name: 'role'},
			],
			userRole: [
				{key: '0', name: 'company'},
				{key: '1', name: 'colleague'},
				{key: '2', name: 'non_partner_accountant'},
				{key: '3', name: 'partner_accountant'},
				{key: '4', name: 'master_accountant'},
				{key: '5', name: 'inhouse_user'},
			],
			accountingField: [
				{key: '0', name: '[Fortnox] - FORTNOX'},
				{key: '1', name: '[Visma] - Visma'},
				{key: '2', name: '[Vismaadmin] - Visma Admin'},
				{key: '3', name: '[Vismanet] - VISMA NET'},
			],
			activeList: [
				{key: 1, name: 'yes'},
				{key: 0, name: 'no'},
			],
			statusList: [
				{key: 1, name: 'active'},
				{key: 0, name: 'inactive'},
				{key: 2, name: 'remove'},
				{key: 3, name: 'paused'},
				{key: 4, name: 'cancelled'},
			],
			sortValue: '',
			filterList: {
				role: '',
				accounting: '',
				active: '',
				datetime: '',
				status: ''
			},
			dateTimeField: [],
			loading: false,
			vloading: false,
		}
	},
	methods: {
		removeUser: function (order_id) {
			let orderIndex = this.users.results.findIndex(item => item.id === order_id)
			if (orderIndex > -1) {
				this.users.results.splice(orderIndex, 1)
			}
		},
		onChange: function () {
			let URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `?page_size=${this.limit}`
			if (this.searchName) {
				URL += `&search=${this.searchName}`
			}
			if (this.filterList.role) {
				URL += `&role=${this.filterList.role}`
			}
			if (this.filterList.accounting) {
				URL += `&accounting=${this.filterList.accounting}`
			}
			if ([0,1].includes(this.filterList.active)) {
				URL += `&is_active=${this.filterList.active}`
			}
			if (this.filterList.status) {
				URL += `&status=${this.filterList.status}`
			}
			if (this.filterList.datetime) {
				URL += `&date_from=${this.filterList.datetime[0]}&date_to=${this.filterList.datetime[1]}`
			}
			this.vloading = true
			this.$store.dispatch('house/customGetRequest', {URL}).then(resp => {
				this.vloading = false
				this.users = resp.data.data
			}).catch(err => {
				this.vloading = false
			})
		},
		searchInputFilterOrder: function () {
			if (this.searchName) {
				let URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `?page_size=${this.limit}&search=${this.searchName}`
				this.vloading = true
				this.$store.dispatch('house/customGetRequest', {URL}).then(resp => {
					this.vloading = false
					this.users = resp.data.data
				}).catch(err => {
					this.vloading = false
				})
			} else {
				this.getUsers()
			}
		},
		sortUser: function (sortValue) {
			this.sortValue = sortValue
			let URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `?page_size=${this.limit}&ordering=${sortValue}`
			this.vloading = true
			this.$store.dispatch('house/customGetRequest', {URL}).then(resp => {
				this.vloading = false
				this.users = resp.data.data
			}).catch(err => {
				this.vloading = false
			})
		},
		changePageNumber: function (number) {
			let URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `?page=${number}&page_size=${this.limit}`
			if (this.sortValue) {
				URL += `&ordering=${this.sortValue}`
			}
			if (this.searchName) {
				URL += `&search=${this.searchName}`
			}
			this.vloading = true
			this.$store.dispatch('house/customGetRequest', {URL}).then(resp => {
				this.vloading = false
				this.users = resp.data.data
			}).catch(err => {
				this.vloading = false
			})
		},
		onChangeLimit: function () {
			window.localStorage.setItem('user_limit', this.limit)
			let URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `?page_size=${this.limit}`
			if (this.sortValue) {
				URL += `&ordering=${this.sortValue}`
			}
			if (this.searchName) {
				URL += `&search=${this.searchName}`
			}
			this.vloading = true
			this.$store.dispatch('house/customGetRequest', {URL}).then(resp => {
				this.vloading = false
				this.users = resp.data.data
			}).catch(err => {
				this.vloading = false
			})
		},
		getUsers() {
			let URL = this.$services.endpoints.INHOUSE_USER_MANAGER + `?page_size=${this.limit}`
			this.vloading = true
			this.$store.dispatch('house/customGetRequest', {URL}).then(resp => {
				this.vloading = false
				this.users = resp.data.data
			}).catch(err => {
				this.vloading = false
			})
		}
	},
	mounted() {
		this.limit = window.localStorage.getItem('user_limit') !== null ? window.localStorage.getItem('user_limit') : 20
		this.getUsers()
	}
}
</script>

<style scoped>

</style>
