<template>
	<div class="app-main__inner">
		<div class="main-card mb-3">

			<div class="row mb-3">
				<div class="col-md-12">
					<div class="flex justify-content-end">
						<a href="javascript:void(0)" v-on:click="onOpenDrawer"
						   class="c-btn btn-primary btn text-sm font-black btn-lg border btn-border-all btn-full-theme text-white hover:text-blue-900">
							<span class="text-xs-custom">{{ $t('addNotification') }}</span>
						</a>

					</div>

				</div>
			</div>
			<div class="main-card mb-3 card">
				<div class="card-body">
					<div class="row mb-4">
						<div class="col-md-4">
							<div class="flex justify-start lg:justify-end w-full">
								<el-select v-model="accounting" :placeholder="$t('AccountingType')"
										   size="mini w-full" v-on:change="onFilter" clearable
								>
									<el-option
										v-for="(item,key) in accountingType"
										:key="key"
										:label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex justify-start lg:justify-end w-full">
								<el-select v-model="app_type" :placeholder="$t('appType')"
										   size="mini w-full" v-on:change="onFilter" clearable
								>
									<el-option
										v-for="(item,key) in subscriptionTypes"
										:key="key"
										:label="item[1]"
										:value="item[0]">
									</el-option>
								</el-select>
							</div>
						</div>
						<div class="col-md-4">
							<div class="flex justify-start lg:justify-end w-full">
								<div class="w-full">
									<input type="text" class="form-input text-xs h-8 border border-pink-500 w-full"
										   :placeholder="$t('search')"
										   v-on:input="searchInput"
										   v-model="searchName"/>
								</div>
							</div>
						</div>
					</div>


				</div>
			</div>
		</div>
		<div>
			<div class="w-full table-responsive bg-white table-container">
				<table style="width: 100%;" id="examplemmm"
					   class="table table-hover table-striped table-bordered bg-white table-auto overflow-x-scroll w-full block">
					<thead>
					<tr>
						<th class="text-xs" v-for="(item,i) in tableHeader" :key="i">
							<span class="flex justify-between">
								<span>{{
										$t(`${item}`)
									}}</span>
								<span class="relative mr-1" v-if="nonSort.includes(item) === false">
													<i v-on:click.prevent="sortNotification(`${item}`)"
													   class="fa fa-chevron-up absolute bottom-2 cursor-pointer"></i>
													<i v-on:click.prevent="sortNotification(`-${item}`)"
													   class="fa fa-chevron-down absolute top-2 cursor-pointer"></i>
												</span>
							</span>
						</th>
						<th class="text-xs"></th>
					</tr>
					</thead>
					<tbody v-loading='vloading'>
					<generic-row v-for="(item,k) in notifications.results" :key="k" :data-obj="item"
								 :endpoint="$services.endpoints.NOTIFICATIONS" :mode="'admin'"
								 :show-edit="true" :show-view="true" :show-delete="true"
								 :display-field="tableHeader" @removeObj="removeNotification"
								 :edit-option="{edit_name: 'open-notification-edit'}"
								 :date-time-field="dateTimeField"/>
					</tbody>
				</table>
			</div>

			<div class="float-right mt-3 mb-5">
				<paginate
					:page-count="notifications.total_page"
					:click-handler="changePageNumber"
					:prev-text="$t('prev')"
					:next-text="$t('next')"
					:container-class="'pagination'">
				</paginate>
			</div>
		</div>
	</div>
</template>

<script>
import genericRow from "./genericResources/genericRow";
import Paginate from "vuejs-paginate";
import 'vue-json-pretty/lib/styles.css';

export default {
	name: "notificationComponent",
	components: {
		genericRow, paginate: Paginate,

	},
	data: function () {
		return {
			searchName: '',
			accounting: '',
			sortValue: '',
			app_type: '',
			accountingType: [],
			subscriptionTypes: [],
			dateTimeField: [
				'created_at'
			],
			tableHeader: [
				"app_type",
				"title",
				"schedule_date",
				"created_at",
			],
			nonSort: ['text'],
			vloading: false,
			notifications: {
				total_page: 1,
				results: []
			},
			limit: 50
		}
	},

	methods: {
		sortNotification: function (sortValue) {
			this.sortValue = sortValue
			this.onFilter()
		},
		computeFilter: function () {
			let URL = ''
			if (this.accounting) {
				URL += `&accounting_id=${this.accounting}`
			}
			if (this.app_type) {
				URL += `&app_type=${this.app_type}`
			}
			if (this.searchName) {
				URL += `&search=${this.searchName}`
			}
			if (this.sortValue) {
				URL += `&ordering=${this.sortValue}`
			}
			return URL

		},
		onFilter() {
			let URL = this.$services.endpoints.NOTIFICATIONS + `?page_size=${this.limit}`
			if (this.computeFilter()) {
				URL += this.computeFilter()
			}
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					// this.notifications = res.data
					this.notifications.results = res.data.data.results
					this.notifications.total_page = res.data.data.total_page
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		searchInput: function () {
			const URL = this.$services.endpoints.NOTIFICATIONS + `?page_size=${this.limit}&search=${this.searchName}`
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					// this.notifications = res.data
					this.notifications.results = res.data.data.results
					this.notifications.total_page = res.data.data.total_page
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		changePageNumber: function (number) {
			let URL = this.$services.endpoints.NOTIFICATIONS + `?page_size=${this.limit}&page=${number}`
			if (this.computeFilter()) {
				URL += this.computeFilter()
			}
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					// this.notifications = res.data
					this.notifications.results = res.data.data.results
					this.notifications.total_page = res.data.data.total_page
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		},
		onOpenDrawer: function () {
			window.Bus.$emit('open-notification-create', {})
		},
		getAccountingType: function () {
			let URL = ''
			this.$store.dispatch('house/getAccountingList').then(resp => {
				this.accountingType = resp.data.data
			})
		},
		getSubscriptionType: function () {
			this.$store.dispatch('house/getSubscriptionTypes').then(resp => {
				this.subscriptionTypes = resp.data.data
			})
		},
		removeNotification: function (notification_id) {
			let customerIndex = this.notifications.results.findIndex(item => item.id === notification_id)
			if (customerIndex > -1) {
				this.notifications.results.splice(customerIndex, 1)
			}
		},
		getNotifications() {
			const URL = this.$services.endpoints.NOTIFICATIONS + `?page_size=${this.limit}`
			this.$store.dispatch('house/customGetRequest', {URL})
				.then(res => {
					this.vloading = false
					// this.notifications = res.data
					this.notifications.results = res.data.data.results
					this.notifications.total_page = res.data.data.total_page
				}).catch((err) => {
				this.vloading = false
				if (err.response.status === 400) {
					if (err.response.data.code === 401) {
						this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
							.then(_ => {

							})
							.catch(_ => {
								this.$store.commit('auth/CLEAR_AUTH_USER', null)
								window.Bus.$emit('sign-out')
							})
					}
				}
			})
		}
	},
	mounted() {
		this.getNotifications()
		this.getAccountingType()
		this.getSubscriptionType()
		let self = this
		window.Bus.$on('reload-notification', e => {
			self.getNotifications()
		})
	}
}
</script>

<style scoped>

</style>
