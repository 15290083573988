import { render, staticRenderFns } from "./AppReport.vue?vue&type=template&id=bb70cddc&scoped=true&"
import script from "./AppReport.vue?vue&type=script&lang=js&"
export * from "./AppReport.vue?vue&type=script&lang=js&"
import style0 from "./AppReport.vue?vue&type=style&index=0&id=bb70cddc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb70cddc",
  null
  
)

export default component.exports