<template>
	<tr>
		<td v-for="(item,k) in displayField" :key="k">
			<span v-if="dateTimeField.includes(item)">
					{{ parseDateTime(user[item.name]) }}
			</span>
			<span v-else-if="item.name ==='company'">
				<span v-if="user[item.name]">
					{{ user[item.name].name }}
				</span>
			</span>
			<span v-else-if="item.name === 'status'">
				<span v-if="user[item.name] === 1" class="badge badge-success">{{ $t('active') }}</span>
				<span v-else class="badge bg-red-500 text-white">{{ $t('inactive') }}</span>
			</span>
			<span v-else-if="item.name === 'is_accountant'">
				<span v-if="user[item.name] === true" class="badge badge-success">{{ $t('yes') }}</span>
				<span v-else class="badge bg-red-500 text-white">{{ $t('no') }}</span>
			</span>
			<span v-else>
				{{ user[item.name] }}
			</span>
		</td>
		<td>
		<td class="text-center flex">
			<span class="mr-2 cursor-pointer" v-on:click="onView" v-if="showView === true"><i
				class="fa fa-eye icon-color"></i></span>
			<span class="mr-2 cursor-pointer" v-on:click="onEdit" v-if="showEdit === true"><i
				class="iconly-brokenEdit icon-color"></i></span><span class="mr-2 cursor-pointer" v-on:click="onDelete"
																	  v-if="showDelete === true"><i
			class=" iconly-brokenDelete icon-color"></i></span></td>

	</tr>
</template>

<script>
export default {
	name: "userRow",
	props: {
		user: {
			type: Object
		},
		displayField: {
			type: Array
		},
		dateTimeField: {
			type: Array
		},
		showView: {
			default: false
		},
		showDelete: {
			default: false
		},
		showEdit: {
			default: false
		},
		endpoint: {
			default: ''
		}

	},
	data: function () {
		return {}
	},
	methods: {
		onView: function () {
			window.Bus.$emit('open-am-queries', {data: this.user})
		},
		onDelete: function () {
			const URL = this.endpoint + `/${this.user.id}/`
			this.$confirm(`${this.$t('deleteConfirmation')}?`, '', {
				confirmButtonText: this.$t('yes'),
				cancelButtonText: this.$t('no'),
				type: 'warning',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true;
						instance.confirmButtonText = 'Loading...';
						this.$store.dispatch('house/customDeleteRequest', {URL: URL}).then(res => {
							instance.confirmButtonLoading = false;
							instance.confirmButtonText = this.$t('yes');
							this.$emit('removeObj', this.user.id)
							done()
						}).catch(err => {
							instance.confirmButtonLoading = false;
							instance.confirmButtonText = this.$t('yes');
							this.$services.helpers.notification(this.$t('deleteEntryError'), 'error', this)
						})

					} else {
						done();
					}
				}
			}).then(res => {
			}).catch(() => {

			});
		},
		onEdit: function () {
			window.Bus.$emit('open-company-edit-profile', this.user)
		},
	}
}
</script>

<style scoped>

</style>
